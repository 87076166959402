import {Project} from "../models/Project";
import React, {useContext, useEffect, useRef, useState} from "react";
import {SnackBarContext} from "../../snackbar/context/SnackBarContext";
import {AxiosError, AxiosResponse} from "axios";
import {ErrorResponse} from "../../../models/ErrorResponse";
import styles from "./CreateUser.module.scss";
import TextInput from "../../../components/form/TextInput";
import i18n from "../../../i18n";
import {Autocomplete, AutocompleteValue, Button, debounce, Popper, Slider} from "@mui/material";
import {CreateProjectRequest} from "../models/CreateProjectRequest";
import {
    addClientToProject, addErrorOnCoordinates,
    addReporterToProject,
    createProject,
    getProjectById,
    updateProject
} from "../../../api/project";
import MapBoxMap, {DrawnShapeCoordinates} from "../../mapbox/MapBoxMap";
import {getAllClient} from "../../../api/clients";
import {PaginatedResponse} from "../models/PaginatedResponse";
import {Client} from "../models/Client";
import TextField from "@mui/material/TextField";
import {IFormDataProps} from "../../../components/table/FormRender";
import {handleErrorMessage} from "../../../utils/Common";
import CloseIcon from '@mui/icons-material/Close';

export function ProjectForm({data, setShowForm}: IFormDataProps<Project>) {
    const [formData, setFormData] = useState<CreateProjectRequest>(new CreateProjectRequest());
    const {setSnackBarType, setOpen, setMessage} = useContext(SnackBarContext);
    const [coordinateSquare, setCoordinateSquare] = useState<any>();
    const [adress, setAdress] = useState<string>("");
    const [clientList, setClientList] = useState<Client[]>([]);
    const [clientSelected, setClientSelected] = useState<Client[]>([]);
    const [reporterSelected, setReporterSelected] = useState<Client | null>(new Client());
    const [dataId, setDataId] = useState<number>(0);
    const [hasChanged, setHasChanged] = useState<boolean>(false);
    const [coordinateMargin, setCoordinateMargin] = useState<number|number[]>(0);
    let projectId = 0;

    const anchorRef = useRef(null);
    useEffect(() => {
        if (data?.projectId) {

            setClientSelected(data.clients);
            setDataId(data.projectId);
            getProjectById(data?.projectId).then((response: AxiosResponse<Project>) => {
                let windowStart = new Date(response.data.activeWindowStart);
                let windowEnd = new Date(response.data.activeWindowEnd);
                projectId = response.data.projectId;
                setCoordinateMargin(response.data.coordinates.margin*5000)
                setFormData(
                    {
                        name: response?.data?.name ?? "",
                        activeWindowStart: windowStart.getTime(),
                        activeWindowEnd: windowEnd.getTime(),
                        isActive: response?.data?.isActive ?? true,
                        adress: response?.data?.adress ?? "",
                        coordinates: response?.data?.coordinates ?? null,
                    }
                )
            }).catch((error: AxiosError<ErrorResponse>) => {
                setSnackBarType("error")
                setMessage(handleErrorMessage(error));
                setOpen(true);
            })
            listAllClient("", 0, 100, "");
        }
    }, [data])


    useEffect(() => {
        setFormData((prevData) => ({
            ...prevData,
            adress: adress,
            coordinates: coordinateSquare,
        }));
    }, [adress, coordinateSquare])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setHasChanged(true);
        const {name, value, type} = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: type === "number" ? parseInt(e.target.value, 10) : value,
        }));
    };

    const handleStartDateChange = (date: Date) => {
        setFormData((prevState) => ({
            ...prevState,
            activeWindowStart: date?.getTime(),
        }))
        setHasChanged(true);
    }

    const handleEndDateChange = (date: Date) => {
        setFormData((prevState) => ({
            ...prevState,
            activeWindowEnd: date?.getTime(),
        }))
        setHasChanged(true);
    }

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        dataId ? onUpdate() : onCreate();
    };

    const handleMapData = (project: Project | undefined): DrawnShapeCoordinates | undefined => {
        let coordinates = project?.coordinates;
        if (coordinates?.bottomLeftX) {
            return {
                bottomLeft: [coordinates?.bottomLeftX, coordinates?.bottomLeftY],
                bottomRight: [coordinates?.bottomRightX, coordinates?.bottomRightY],
                topRight: [coordinates?.topRightX, coordinates?.topRightY],
                topLeft: [coordinates?.topLeftX, coordinates?.topLeftY],
            }
        }

        return undefined;
    }

    const listAllClient = (fts: string, offset: number, row: number, order: string) => {
        getAllClient(fts, offset, row, order, true).then((response: AxiosResponse<PaginatedResponse<Client>>) => {
            let clientList = response.data.data.filter((client: Client) => client.active)
            setClientList(clientList);
            let client = clientList.filter((client: Client) => client.email === data?.reporter)[0];
            setReporterSelected(client);
        }).catch((error) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        })
    }

    const onCreate = () => {
        createProject(formData).then(
            (response: AxiosResponse<Project>) => {
                setSnackBarType("success")
                setMessage(i18n.t("project.success"));
                setOpen(true);

                // Reset the form after submission
                setFormData(new CreateProjectRequest());
                setShowForm(false);
            }
        ).catch((error: AxiosError<ErrorResponse>) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        })
    }

    const onUpdate = () => {
        let body = {
            ...formData,
            activeWindowEnd: new Date(formData.activeWindowEnd).getTime(),
            activeWindowStart: new Date(formData.activeWindowStart).getTime(),
            client: clientSelected,
            reporter: reporterSelected ?? new Client()
        };
        updateProject(dataId, body).then((response: AxiosResponse<Project>) => {
            setSnackBarType("success")
            setMessage(i18n.t("project.success_update"));
            setOpen(true);

            // Reset the form after submission
            setFormData(new CreateProjectRequest());
            setShowForm(false);
        }).catch((error: AxiosError<ErrorResponse>) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);
        })
    }
    const handleShapeDrawn = (coordinates: any) => {
        setCoordinateSquare(convertCoordinates(coordinates));
    };
    const convertCoordinates = (coordinates: any) => {
        const {
            bottomLeft: [bottomLeftX, bottomLeftY],
            bottomRight: [bottomRightX, bottomRightY],
            topRight: [topRightX, topRightY],
            topLeft: [topLeftX, topLeftY],
        } = coordinates;

        return {
            bottomLeftX,
            bottomLeftY,
            bottomRightX,
            bottomRightY,
            topRightX,
            topRightY,
            topLeftX,
            topLeftY,
        };
    };

    const addClientsToProject = (value: any) => {
        setClientSelected(value);
        linkClientsToProject(value.map((client: Client) => client.id));
    }

    const addReportersToProject = (value: Client | null) => {
        //TODO : quickfixe to allow client to test , but need to be implemented in more conventional way
        if (value) {
            setReporterSelected(value);
            linkReporterToProject(value);
        } else {
            linkReporterToProject(reporterSelected ?? new Client());
            setReporterSelected(null);
        }

    }
    const linkClientsToProject = (clientIds: number[]) => {
        addClientToProject(dataId, clientIds).then((response: AxiosResponse<Project>) => {
            setSnackBarType("success")
            setMessage(i18n.t("project.success_client"));
            setOpen(true);
        }).catch((error: AxiosError<ErrorResponse>) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);

        });
    }
    const linkReporterToProject = (client: Client) => {

        addReporterToProject(dataId, client.id).then((response: AxiosResponse<Project>) => {
            setSnackBarType("success")
            setMessage(i18n.t("project.success_reporter"));
            setOpen(true);
        }).catch((error: AxiosError<ErrorResponse>) => {
            setSnackBarType("error")
            setMessage(handleErrorMessage(error));
            setOpen(true);

        });

    }

    const PopperMy = function (props: any) {
        return (<Popper {...props} placement={'bottom'} modifiers={[
            {
                name: 'flip',
                enabled: false,
                options: {
                    altBoundary: false,
                    rootBoundary: 'viewport',
                    padding: 8,
                },
            },
            {
                name: 'preventOverflow',
                enabled: false,
                options: {
                    altAxis: false,
                    altBoundary: false,
                    tether: false,
                    rootBoundary: 'viewport',
                    padding: 8,
                },
            }
        ]}/>)
    }
    const updateCoordinates = (text: any) => {
        addErrorOnCoordinates(dataId, text / 5000).then((res) => {
            console.log(res, "res")
        })
    }
    return (
        <div className={styles.main_container}>
            <form className="create_user_form" onSubmit={handleSubmit}>
                <div className={styles.form_group}>
                    <TextInput
                        label={i18n.t("project.name")}
                        type="text"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className={styles.date_container}>
                    <div className={styles.form_group}>
                        <TextInput
                            label={i18n.t("client.start_activity")}
                            type={"date"}
                            name="activeWindowStart"
                            value={formData.activeWindowStart}
                            onChange={handleStartDateChange}
                            required={true}
                        />
                    </div>
                    <label className={styles.date_separate}>&nbsp;-&nbsp;</label>
                    <div className={styles.form_group}>
                        <TextInput type={"date"}
                                   label={i18n.t("client.end_activity")}
                                   name={"activeWindowEnd"}
                                   value={formData.activeWindowEnd}
                                   onChange={handleEndDateChange}
                                   required={true}/>
                    </div>
                </div>

                {(dataId > 0 && clientList) && (
                    <div>
                        <div className={styles.select}>
                            <div className={styles.client_container}>
                                <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={clientList}
                                    getOptionLabel={(option) => option?.username ?? ""}
                                    filterSelectedOptions
                                    clearOnBlur={false}
                                    value={clientSelected ?? null}
                                    onChange={(e, value) => addClientsToProject(value)}
                                    PopperComponent={PopperMy}
                                    ref={anchorRef}
                                    disableClearable={clientSelected != null}
                                    renderInput={(params) => (
                                        // @ts-ignore
                                        <TextField
                                            {...params}
                                            label={i18n.t("project.add_user.label")}
                                            placeholder={i18n.t("project.add_user.placeholder")}
                                        />
                                    )}

                                />
                            </div>
                        </div>
                        <div className={styles.select}>
                            <div className={styles.client_container}>
                                <Autocomplete
                                    id="tags-outlined"
                                    options={clientList}
                                    getOptionLabel={(option) => option?.username ?? ""}
                                    filterSelectedOptions
                                    value={reporterSelected ?? null}
                                    onChange={(e, value) => addReportersToProject(value)}
                                    PopperComponent={PopperMy}
                                    clearIcon={<CloseIcon/>}
                                    ref={anchorRef}
                                    renderInput={(params) => (
                                        // @ts-ignore
                                        <TextField
                                            {...params}
                                            label={i18n.t("project.add_reporter.label")}
                                            placeholder={i18n.t("project.add_reporter.placeholder")}
                                        />
                                    )}

                                />
                            </div>
                        </div>
                    </div>
                )}


                <p className={styles.text_map}>{i18n.t("project.define_map")}</p>

                <MapBoxMap onShapeDrawn={handleShapeDrawn} adressUpdate={setAdress} data={handleMapData(data)}/>
                {dataId > 0 &&
                    <div style={{width:'50%'}}>
                        <p>Ajouter de la marge aux coordonées :</p>
                        <Slider
                            aria-label="Coordonées"
                            defaultValue={0}
                            value={coordinateMargin}
                            onChange={(_,e)=>setCoordinateMargin(e)}
                            onChangeCommitted={(_, e) => updateCoordinates(e)}
                            color="primary"
                        />
                    </div>
                }

                <div className={styles.button_container}>
                    <Button variant={"contained"}
                            className={styles.button}
                            type="submit"
                            disabled={formData.name === "" || formData.activeWindowStart === 0 ||
                                formData.activeWindowEnd === 0 || formData.adress === "" || formData.coordinates === null || !hasChanged}
                    >{i18n.t("user.save")}</Button>
                </div>


            </form>

        </div>

    );
}
