export const LOGIN_PAGE = "/login";
export const HOME_PAGE = "/home";
export const USER_PAGE = "/gestionnaire";
export const CLIENT_PAGE = "/client";
export const PROJECT_PAGE = "/projet";
export const PROJECT_DETAIL_PAGE = "/projet/detail";
export const REPORTING_PAGE = "/reporting";

// TODO change to env
/*
export const BASE_URL = "http://cmwb.dlsg.be:8080/";
*/
export const BASE_URL = "http://capts.be:8080/";

export const MAPBOX_TOKEN = 'pk.eyJ1IjoicmF5ZG9uYmUiLCJhIjoiY2xreHRydjI2MWQ4eTNkcHAwbGdtd3h3aSJ9.s38YI_KeaJAlpJXPB2CbYA';


export const CLIENT_URL = "/management/client";
export const LOCAL_STORAGE_TOKEN = "TOKEN";
export const SESSION_STORAGE_LOGGED = "LOGGED";
export const LOCAL_STORAGE_NAVBAR = "NAVBAR";
export const LOCAL_STORAGE_SAVED_USERNAME = "SAVED_USERNAME";
